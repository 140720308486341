import { Services } from "@app/services";
import { Address, AssetMessage, Asset_data, Asset_Station, FloorMessage, FloorplanMessage, GeoJsonMessage, LocationMessage, OrganizationMessage, Point, StationMessage, Station_data, Station_Settings, Station_System_Record, UserMessage } from "@protos";

export function parseOrg(resp: any): OrganizationMessage {
	let org_message = new OrganizationMessage();
	org_message.setId(resp.id);
	org_message.setName(resp.name);
	org_message.setCreated(resp.created);
	org_message.setEmail(resp.email);
	org_message.setModified(resp.modified);
	org_message.setApiUrl(resp.API_URL);

	org_message.setMqttPassword(resp.MQTT_Password);
	org_message.setMqttPort(resp.MQTT_Port);
	org_message.setMqttUrl(resp.MQTT_URL);
	org_message.setMqttUsername(resp.MQTT_Username);
	org_message.setOtaPort(resp.OTA_PORT);
	org_message.setOtaUrl(resp.OTA_URL);

	let address = new Address();
	address.setStreet(resp.address.street);
	address.setCity(resp.address.city);
	address.setState(resp.address.state);
	address.setCity(resp.address.street);
	address.setZipcode(resp.address.zip);
	org_message.setAddress(address);

	return org_message;
}

export function parseUser(resp: any): UserMessage {
	let user_message = new UserMessage();

	user_message.setRole(resp.role);
	user_message.setId(resp._id);
	user_message.setUsername(resp.username);
	user_message.setEmail(resp.email);
	user_message.setOrganization(resp.organization);
	user_message.setCreated(resp.reated);
	user_message.setModified(resp.modified);

	return user_message;
}

export function parseDevice(resp: any): AssetMessage | StationMessage {
	let asset_message: AssetMessage | StationMessage = undefined;

	if (resp.service == Services.Tag) {
		asset_message = parseAsset(resp) as AssetMessage;
	} else if (resp.service == Services.Station) {
		asset_message = parseStation(resp) as StationMessage;
	}

	return asset_message;
}

export function parseAsset(resp: any): AssetMessage {
	let asset_message = new AssetMessage();

	let point = new Point();
	point.setX(resp.last_location.coordinates[0]);
	point.setY(resp.last_location.coordinates[1]);
	asset_message.setLatLong(point);

	asset_message.setId(resp._id);
	asset_message.setMacaddress(resp.macAddress);
	asset_message.setCreated(resp.created);
	asset_message.setModified(resp.modified);

	asset_message.setName(resp.name);
	asset_message.setDetails(resp.details);
	asset_message.setService(resp.service);

	if(resp.rssi_is_used){
		console.log("resp", resp);
		asset_message.rssi_is_used = true;
	}
	else{
		asset_message.rssi_is_used = false;
	}

	if(resp.rssi_last_active_epoch){
		asset_message.rssi_last_active_epoch = resp.rssi_last_active_epoch;
	}
	if(resp.rssi_last_inactive_epoch){
		asset_message.rssi_last_inactive_epoch = resp.rssi_last_inactive_epoch;
	}
	
	if(resp.rssi_last_button_pressed_epoch){
		asset_message.rssi_last_button_pressed_epoch = resp.rssi_last_button_pressed_epoch;
	}
	if(resp.rssi_last_button_depressed_epoch){
		asset_message.rssi_last_button_depressed_epoch = resp.rssi_last_button_pressed_epoch;
	}
	if(resp.rssi_last_ext_system_enabled_epoch){
		asset_message.rssi_last_ext_system_enabled_epoch = resp.rssi_last_ext_system_enabled_epoch;
	}
	if(resp.rssi_last_ext_system_disabled_epoch){
		asset_message.rssi_last_ext_system_disabled_epoch = resp.rssi_last_ext_system_disabled_epoch;
	}
	if(resp.rssi_last_ext_powered_epoch){
		asset_message.rssi_last_ext_powered_epoch = resp.rssi_last_ext_powered_epoch;
	}
	if(resp.rssi_last_ext_unpowered_epoch){
		asset_message.rssi_last_ext_unpowered_epoch = resp.rssi_last_ext_unpowered_epoch;
	}
	if(resp.rssi_last_usb_powered_epoch){
		asset_message.rssi_last_usb_powered_epoch = resp.rssi_last_usb_powered_epoch;
	}
	if(resp.rssi_last_usb_unpowered_epoch){
		asset_message.rssi_last_usb_unpowered_epoch = resp.rssi_last_usb_unpowered_epoch;
	}
	if(resp.rssi_last_is_motion_epoch){
		asset_message.rssi_last_is_motion_epoch = resp.rssi_last_is_motion_epoch;
	}
	if(resp.rssi_last_no_motion_epoch){
		asset_message.rssi_last_no_motion_epoch = resp.rssi_last_no_motion_epoch;
	}
	if(resp.temperature){
		asset_message.temperature = resp.temperature;
	}
	if(resp.temperature_alarm_exists){
		asset_message.temperature_alarm_exists = resp.temperature_alarm_exists;
	}
	if(resp.temperature_alarm_value){
		asset_message.temperature_alarm_value = resp.temperature_alarm_value;
	}
	if(resp.temperature_alarm_epoch){
		asset_message.temperature_alarm_epoch = resp.temperature_alarm_epoch;
	}

	if(resp.eddy_raw_battery){
		asset_message.eddy_raw_battery = resp.eddy_raw_battery;
	}
	if(resp.eddy_raw_battery_0){
		asset_message.eddy_raw_battery_0 = resp.eddy_raw_battery_0;
	}
	if(resp.eddy_raw_battery_1){
		asset_message.eddy_raw_battery_1 = resp.eddy_raw_battery_1;
	}
	
	if(resp.battery_level){
		asset_message.setBatteryLevel(resp.battery_level);
	}
	if(resp.battery_sent_command_timestamp){
		asset_message.setBatterySentCommandTimestamp(resp.battery_sent_command_timestamp);
	}
	if(resp.battery_sent_command_to){
		asset_message.setBatterySentCommandTo(resp.battery_sent_command_to);
	}
	if(resp.battery_updated_by){
		asset_message.setBatteryUpdatedBy(resp.battery_updated_by);
	}
	if(resp.battery_updated_timestamp){
		asset_message.setBatteryUpdatedTimestamp(resp.battery_updated_timestamp);
	}

	if(resp.ring_command_created_timestamp){
		asset_message.setRingCommandCreatedTimestamp(resp.ring_command_created_timestamp);
	}
	if(resp.ring_command_sent_timestamp){
		asset_message.setRingCommandSentTimestamp(resp.ring_command_sent_timestamp);
	}
	if(resp.ring_command_sent_to){
		asset_message.setRingCommandSentTo(resp.ring_command_sent_to);
	}
	if(resp.ring_command_successful_timestamp){
		asset_message.setRingCommandSuccessfulTimestamp(resp.ring_command_successful_timestamp);
	}
	if(resp.ring_command_successful_by){
		asset_message.setRingCommandSuccessfulBy(resp.ring_command_successful_by);
	}
	if(resp.ring_command_successful_counter){
		asset_message.setRingCommandSuccessfulCounter(resp.ring_command_successful_counter);
	}


	if (resp.data) {
		let data = new Asset_data();
		if (resp.data.last_station) {
			let last_station = new Asset_Station();
			//asset.addProtoParents(device.data.last_station._id);
			last_station.setId(resp.data.last_station._id);
			last_station.setRssi(resp.data.last_station.rssi);

			let lat_long = new Point();
			lat_long.setX(resp.data.last_station.latitude);
			lat_long.setY(resp.data.last_station.longitude);
			last_station.setLatLong(lat_long);

			last_station.setAccuracy(resp.data.last_station.accuracy);
			last_station.setTimestamp(resp.data.last_station.timestamp);
			last_station.setLocation(resp.data.last_station.location_id);
			last_station.setLocationName(resp.data.last_station.location_name);
			data.setLastStation(last_station);
		}
		if (resp.data.calculated_position) {
			let calculated_position = new Asset_Station();
			//asset.addProtoParents(device.data.calculated_position._id);
			calculated_position.setName(resp.data.calculated_position.name);
			calculated_position.setId(resp.data.calculated_position._id);
			calculated_position.setRssi(resp.data.calculated_position.rssi);

			let lat_long = new Point();
			lat_long.setX(resp.data.calculated_position.latitude);
			lat_long.setY(resp.data.calculated_position.longitude);
			calculated_position.setLatLong(lat_long);

			calculated_position.setAccuracy(resp.data.calculated_position.accuracy);
			calculated_position.setTimestamp(resp.data.calculated_position.timestamp);
			calculated_position.setLocation(resp.data.calculated_position.location_id);
			calculated_position.setLocationName(resp.data.calculated_position.location_name);

			data.setCalculatedPosition(calculated_position);

			if(resp.data.calculated_position.location_entered_timestamp){
				asset_message.setLocationEnteredTimestamp(resp.data.calculated_position.location_entered_timestamp);
			}
		}
		if (resp.data.history) {
			resp.data.history.forEach(station_resp => {
				let history_station = new Asset_Station();
				asset_message.addProtoParents(station_resp._id);
				history_station.setId(station_resp._id);
				history_station.setRssi(station_resp.rssi);

				let lat_long = new Point();
				lat_long.setX(station_resp.latitude);
				lat_long.setY(station_resp.longitude);
				history_station.setLatLong(lat_long);

				history_station.setAccuracy(station_resp.accuracy);
				history_station.setTimestamp(station_resp.timestamp);
				history_station.setLocation(station_resp.location_id);
				history_station.setLocationName(station_resp.location_name);

				data.addHistory(history_station);
			})
		}
		asset_message.setData(data);

	}

	asset_message.setFloor(resp.floor);
	asset_message.setLastSeen(resp.last_seen);
	asset_message.setLocation(resp.location);
	asset_message.setDetails(resp.details);

	// if(resp.macAddress == "ff7e320f5170"){
	// 	console.log(":resp: ", resp);
	// 	console.log(":asset_message.toObject()", asset_message.toObject());
	// }

	return asset_message;
}

export function parseStation(resp: any): (AssetMessage | StationMessage) {
	let station_message = new StationMessage();

	let point = new Point();
	point.setX(resp.last_location.coordinates[0]);
	point.setY(resp.last_location.coordinates[1]);
	station_message.setLatLong(point);

	station_message.setId(resp._id);
	station_message.setMacaddress(resp.macAddress);
	station_message.setCreated(resp.created);
	station_message.setProtoParent(resp.floor);
	station_message.setLocation(resp.location);
	station_message.setModified(resp.modified);
	station_message.setName(resp.name);
	station_message.setService(resp.service);

	let data = new Station_data();
	let record = new Station_System_Record();

	if (resp.data) {
		if (resp.data.message) {

			if(resp.data.message.systemRecord){

				if(resp.data.message.systemRecord.nextOta){
					record.setNextOta(resp.data.message.systemRecord.nextOta);
				}
				if(resp.data.message.systemRecord.silicon){
					record.setSilicon(resp.data.message.systemRecord.silicon);
				}
				if(resp.data.message.systemRecord.rssi){
					record.setWifiRssi(resp.data.message.systemRecord.rssi);
				}
				let version: number[] = []
	
				if (resp.data.message.systemRecord.version) {
					Object.keys(resp.data.message.systemRecord.version).forEach((token) => {
						version.push(Number.parseInt(resp.data.message.systemRecord.version[token]));
					});
					record.setVersionList(version);
				}
	
				if (resp.data.message.systemRecord.settingVersion) {
					record.setSettingversion(resp.data.message.systemRecord.settingVersion);
				}
			}


			data.setTimestamp(resp.data.message.timestamp);
		}
	}

	let settings = new Station_Settings();
	if (resp.settings) {
		settings.setVersion(resp.settings.version);
		settings.setBinversion(resp.settings.binVersion);
	}
	station_message.setSettings(settings)

	data.setRecord(record);
	station_message.setData(data);
	station_message.setLastSeen(resp.last_seen);

	if(resp.macAddress == "b4e62dc1589d"){
		console.log("station_resp", resp);
		console.log("station_message", station_message.toObject());
	}

	return station_message;
}

export function parseFloor(resp: any): FloorMessage {
	let floor_message: FloorMessage = new FloorMessage();
	let floorplan_message: FloorplanMessage = new FloorplanMessage();

	if (resp.floor_plan_imgJson) {
		let pointOne = new Point();
		pointOne.setX(resp.floor_plan_imgJson.cornerOne[0]);
		pointOne.setY(resp.floor_plan_imgJson.cornerOne[1]);
		floorplan_message.setCornerOne(pointOne);

		let pointTwo = new Point();
		pointTwo.setX(resp.floor_plan_imgJson.cornerTwo[0]);
		pointTwo.setY(resp.floor_plan_imgJson.cornerTwo[1]);
		floorplan_message.setCornerTwo(pointTwo);

		floorplan_message.setImage(resp.floor_plan_imgJson.image);
	} else {
		let pointOne = new Point();
		pointOne.setX(0);
		pointOne.setY(0);
		floorplan_message.setCornerOne(pointOne);

		let pointTwo = new Point();
		pointTwo.setX(0);
		pointTwo.setY(0);
		floorplan_message.setCornerTwo(pointTwo);
		floorplan_message.setImage(undefined);
	}

	floor_message.setFloorplan(floorplan_message);

	let geoJson: GeoJsonMessage = new GeoJsonMessage();
	if (resp.floor_plan_geoJson) {
		geoJson.setJson(resp.floor_plan_geoJson);
	}
	floor_message.setGeojson(geoJson);


	floor_message.setId(resp.id);
	floor_message.setName(resp.name);
	floor_message.setCreated(resp.created);
	floor_message.setModified(resp.modified);
	//floor.setProtoChildrenList();
	floor_message.setProtoParent(resp.location);
	// floor.setStationCount();

	return floor_message;
}

export function parseLocation(resp: any): LocationMessage {
	let location_message: LocationMessage = new LocationMessage();

	let point = new Point();

	if (resp.lat_long) {
		point.setX(resp.lat_long.coordinates[0]);
		point.setY(resp.lat_long.coordinates[1]);
	} else {
		point.setX(0);
		point.setY(0);
	}
	location_message.setLatLong(point);

	location_message.setId(resp.id);
	location_message.setName(resp.name);
	location_message.setCreated(resp.created);
	location_message.setModified(resp.modified);
	location_message.setImageId(resp.map_photo);
	location_message.setImageScale(resp.map_scale);

	let floor_ids: string[] = [];
	if (resp.floors) {
		resp.floors.forEach(floor => {
			floor_ids.push(floor.id);
		});
	}

	location_message.setProtoChildrenList(floor_ids);
	location_message.setProtoParent(resp.organization);

	return location_message
}